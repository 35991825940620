import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';

import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

import { getPhoneNumber } from 'util/globalVariables';

import './no-invitations-available.scss';

const NoInvitationsAvailable = ({
    data,
    location
}: {
    data: GatsbyTypes.NoInvitationsAvailableDataQuery;
    location: Location;
}) => {
    const { t } = useTranslation();

    return (
        <ErrorScreenLayout title={t('pages.joinMembershipPlan.noInvitationsAvailable.title')}>
            <Container className="no-invitations-available">
                <Row>
                    <Col xs={12}>
                        <p className="body">{t('pages.joinMembershipPlan.noInvitationsAvailable.body')}</p>
                        <p
                            className="instructions"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.joinMembershipPlan.noInvitationsAvailable.instructions', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        />
                        <Button
                            className="btn-bold sm-full"
                            type="button"
                            onClick={() => navigate('/')}
                            label={t('pages.joinMembershipPlan.noInvitationsAvailable.cta')}
                        />
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default NoInvitationsAvailable;

export const query = graphql`
    query NoInvitationsAvailableData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
